<template>
	<div>
		<Navigation />
		<!-- 轮播图 -->
		<!-- <el-skeleton style="width:100%" :loading="loading" animated>
			<template slot="template">
				<el-skeleton-item variant="button"
					style="width: 100%; height: 640px;padding-top: 10rem;box-sizing: border-box;" />
			</template>
<template>
				<el-carousel trigger="click" arrow="never">
					<el-carousel-item v-for="(item, index) in bannerImg" :key="index" height="640px">
						<img :src="item.image ? item.image : ''" alt="" style="width: 100%; object-fit: cover;" />
					</el-carousel-item>
				</el-carousel>
			</template>
</el-skeleton> -->
		<img :src="bannerImg" alt="" class="banner">
		<!-- 新闻展示 -->
		<div style="background: #3d628e">
			<!-- <div style="width: 100%;height: 116px;background: #3D628E;opacity: 0.7;position: absolute;left: 0;z-index: -1;"></div> -->
			<ul class="flex mediaLi" style="
			margin: 0 auto;
					width: 80%;
					min-width: 1200px;
					height: 116px;
					/* padding: 0 16rem; */
					/* box-sizing: border-box; */
					z-index: 1;
				">
				<li @click="goMedia(index)" v-for="(item, index) in mediaList" :key="index" style="flex: 1">
					<h3>{{ item.title }}</h3>
					<p>{{ item.conts }}</p>
					<span>立即选择</span>
				</li>
			</ul>
		</div>
		<!-- 媒体发布 -->
		<div class="media_release_box">
			<Title :title="require('../assets/home/title/1.png')" remark="多渠道选择，自由组合打造专属自己的媒体曝光圈！" />
			<div class="flex list" style="justify-content: space-between">
				<div class="ct_box">
					<div class="title_box">
						<p class="title">资讯门户</p>
						<p class="title2">全网覆盖引爆看点，助力企业品牌建设</p>
					</div>
					<ul class="media_list">
						<li class="flex" v-for="(item, index) in mediaRlList" :key="index">
							<img :src="item.logo" alt="" />
							<p class="name">{{ item.title }}</p>
							<!-- <p class="price">￥{{ item.price }}<span>起</span></p> -->
						</li>
					</ul>
				</div>

				<div class="ct_box">
					<div class="title_box">
						<p class="title">自媒体</p>
						<p class="title2">流量为王的时代，引流推广的不二选择</p>
					</div>
					<ul class="media_list">
						<li class="flex" v-for="(item, index) in mediaRlList2" :key="item.id">
							<img :src="item.logo" alt="" />
							<p class="name">{{ item.title }}</p>
							<!-- <p class="price">￥{{ item.price }}<span>起</span></p> -->
						</li>
					</ul>
				</div>

				<div class="ct_box">
					<div class="title_box">
						<p class="title">纸媒介</p>
						<p class="title2">传统纸媒更高端、更权威，真能彰显实力</p>
					</div>
					<ul class="media_list">
						<li class="flex" v-for="(item, index) in mediaRlList3" :key="index">
							<img :src="item.logo" alt="" />
							<p class="name">{{ item.title }}</p>
							<!-- <p class="price">￥{{ item.price }}<span>起</span></p> -->
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- 数据 -->
		<!-- :class="scrollTopNum > scrollTopbox ? 'database_ct_box' : 'database_none_box'" -->
		<div class="database_box">
			<div class="flex database_ct_box">
				<div v-if="scrollTopNum > scrollTopbox">
					<VueCountUp class="text1" :start-value="0" :end-value="middleValue2.value1" :duration="2" />
					<p class="text_hr"></p>
					<p class="text1_p">10年媒体宣发经验，助力企业品牌建设</p>
				</div>
				<div v-if="scrollTopNum > scrollTopbox">
					<VueCountUp class="text1" :delay="1" :start-value="0" :end-value="middleValue2.value2"
						:duration="1" />
					<p class="text_hr"></p>
					<p class="text1_p">17000多条案例，见证我们的实力</p>
				</div>
				<div v-if="scrollTopNum > scrollTopbox">
					<VueCountUp class="text1" :delay="2" :start-value="0" :end-value="middleValue2.value3"
						:duration="1" />
					<p class="text_hr"></p>
					<p class="text1_p">服务超过200多家大型企业单位</p>
				</div>
			</div>
		</div>
		<!-- 我们的优势 -->
		<div class="advantage_box">
			<Title :title="require('../assets/home/title/2.png')" remark="全国1w+的媒体供您选择" />
			<ul class="advantage_ct_box flex">
				<li v-for="(item, index) in advantList" :key="index">
					<img :src="item.imgUrl1" alt="" class="advant-1bg" />
					<img :src="item.imgUrl2" alt="" class="advant-2bg" />
					<p class="adv_title">{{ item.title }}</p>
					<p class="adv_ct">{{ item.content }}</p>
				</li>
			</ul>
		</div>
		<!-- 热门套餐 -->
		<div class="hot_setMeal_box">
			<Title :title="require('../assets/home/title/3.png')" remark="精心挑选，打造全方位媒体曝光圈!" />
			<ul class="flex media">
				<li class="media_li" v-for="(item, index) in hot_setMealList" :key="index">
					<p class="title">{{ item.title }}</p>
					<p class="remark">
						<!-- <span v-for="itme in item.medium_list" :key="itme.id">{{ itme.des }}，</span> -->
						<span>{{ item.des }}</span>
					</p>
					<p class="price">￥{{ item.price }}元</p>
					<p class="more" @click="goPack">了解详情</p>
				</li>

			</ul>
			<!-- <button class="more_but">了解更多</button> -->
		</div>
		<!-- 海量媒体资源 -->
		<div class="More_media_bg">
			<div class="More_media_box">
				<Title :title="require('../assets/home/title/4.png')" remark="全国1W+的线上/线下媒体资源，供您选择!" />
				<ul class="flex list">
					<li v-for="(item, index) in MoreMediaList" :key="index">
						<img :src="item" alt="" />
					</li>
				</ul>
			</div>
		</div>
		<!-- 合作流程 -->
		<div class="partner_box">
			<Title :title="require('../assets/home/title/5.png')" remark="致力于帮助更多有需求的中小企业对营销、拍摄、写稿方面的技巧指导！" />
			<ul class="partner_ul flex">
				<li>
					<img src="../assets/home/flow/register.png" alt="" class="img_1" />
					<img src="../assets/home/flow/register-link.png" alt="" class="img_2" />
					<p>注册充值</p>
				</li>
				<li>
					<img src="../assets/home/flow/medium.png" alt="" class="img_1" />
					<img src="../assets/home/flow/medium-link.png" alt="" class="img_2" />
					<p>选择媒体</p>
				</li>
				<li>
					<img src="../assets/home/flow/release.png" alt="" class="img_1" />
					<img src="../assets/home/flow/release-link.png" alt="" class="img_2" />
					<p>发布文章</p>
				</li>
				<li>
					<img src="../assets/home/flow/search.png" alt="" class="img_1" />
					<img src="../assets/home/flow/search-link.png" alt="" class="img_2" />
					<p>等待审核</p>
				</li>
				<li>
					<img src="../assets/home/flow/success.png" alt="" class="img_1" />
					<img src="../assets/home/flow/success-link.png" alt="" class="img_2" />
					<p>发布成功</p>
				</li>
			</ul>
		</div>
		<!-- 技巧指南 -->
		<div class="vade_bg_box">
			<div class="vade_box">
				<Title :title="require('../assets/home/title/6.png')" remark="全国1w+的媒体供您选择" />
				<div class="tab">
					<p :class="vadeShow == 0 ? 'acticeTab' : ''" @mouseenter="handleMouseEnter('营销技巧')">
						营销技巧
					</p>
					<span>——</span>
					<p :class="vadeShow == 1 ? 'acticeTab' : ''" @mouseenter="handleMouseEnter('拍摄技巧')">
						拍摄技巧
					</p>
					<span>——</span>
					<p :class="vadeShow == 2 ? 'acticeTab' : ''" @mouseenter="handleMouseEnter('写稿技巧')">
						写稿技巧
					</p>
				</div>
				<!-- 数据1 -->
				<div v-show="vadeShow == 0" class="press_box flex">
					<div class="press_lt_box" @click="goDetail(vadeList2[0])">
						<img :src="vadeList2[0].image ? vadeList2[0].image : ''" alt="" style="" />
						<div class="flex" style="margin: 2rem 0">
							<div style="width: 78%; margin-right: 2rem">
								<p class="text1">{{ vadeList2[0].title }}</p>
								<p class="text2">
									{{ vadeList2[0].des }}
								</p>
							</div>
							<div class="data">
								<p>{{ vadeList2[0].d }}</p>
								<span>{{ vadeList2[0].ym }}</span>
							</div>
						</div>
					</div>
					<ul class="press_rt_box flex">
						<li v-for="(item, index) in vadeList2" :key="index" @click="goDetail(item)">
							<p class="text1">
								<span class="data">{{ item.ym }}</span>
								<span class="day">{{ item.d }}</span>
							</p>
							<p class="text2">
								<span class="day">{{ item.title }}</span>
								<span class="data">{{ item.des }}</span>
							</p>
						</li>

					</ul>
				</div>
				<!-- 数据2 -->
				<div v-show="vadeShow == 1" class="press_box flex">
					<div class="press_lt_box" @click="goDetail(vadeList3[0])">
						<img :src="vadeList3[0].image ? vadeList3[0].image : ''" alt="" style="" />
						<div class="flex" style="margin: 2rem 0">
							<div style="width: 78%; margin-right: 2rem">
								<p class="text1">{{ vadeList3[0].title }}</p>
								<p class="text2">
									{{ vadeList3[0].des }}
								</p>
							</div>
							<div class="data">
								<p>{{ vadeList3[0].d }}</p>
								<span>{{ vadeList3[0].ym }}</span>
							</div>
						</div>
					</div>
					<ul class="press_rt_box flex">
						<li v-for="(item, index) in vadeList3" :key="index" @click="goDetail(item)">
							<p class="text1">
								<span class="data">{{ item.ym }}</span>
								<span class="day">{{ item.d }}</span>
							</p>
							<p class="text2">
								<span class="day">{{ item.title }}</span>
								<span class="data">{{ item.des }}</span>
							</p>
						</li>

					</ul>
				</div>
				<!-- 数据3 -->
				<div v-show="vadeShow == 2" class="press_box flex">
					<div class="press_lt_box" @click="goDetail(vadeList4[0])">
						<img :src="vadeList4[0].image ? vadeList4[0].image : ''" alt="" style="" />
						<div class="flex" style="margin: 2rem 0">
							<div style="width: 78%; margin-right: 2rem">
								<p class="text1">{{ vadeList4[0].title }}</p>
								<p class="text2">
									{{ vadeList4[0].des }}
								</p>
							</div>
							<div class="data">
								<p>{{ vadeList4[0].d }}</p>
								<span>{{ vadeList4[0].ym }}</span>
							</div>
						</div>
					</div>
					<ul class="press_rt_box flex">
						<li v-for="(item, index) in vadeList4" :key="index" @click="goDetail(item)">
							<p class="text1">
								<span class="data">{{ item.ym }}</span>
								<span class="day">{{ item.d }}</span>
							</p>
							<p class="text2">
								<span class="day">{{ item.title }}</span>
								<span class="data">{{ item.des }}</span>
							</p>
						</li>

					</ul>
				</div>
				<!-- <button class="more_but">了解更多</button> -->
			</div>
		</div>

		<!-- 策划方案 -->m
		<div class="planning_box">
			<p>获取企业品牌曝光策划方案</p>
			<i></i>
			<span>联系专业的商务顾问，方案、专业设计、一对一咨询制定、及其报价详情</span>
		</div>
		<footerBot />

	</div>
</template>

<script>
import VueCountUp from 'vue-countupjs' //引入
import Title from '@/components/Title.vue'
export default {
	name: '',
	components: { VueCountUp, Title },
	created() {
		this.BannerImgFn()

	},
	activated() {
		console.log('组件被激活了，activated')
	},
	mounted() {
		if (window.screen.width < 500) {
			window.location.href = '/h5';
			// console.log(1111)
		};
		// 监听页面滚动事件
		window.addEventListener('scroll', this.scrolling)
		// setTimeout(() => {
		// 	var database = document.getElementsByClassName('database_ct_box')
		// 	console.log(database);
		// 	this.scrollTopbox = database[0].offsetTop - database[0].offsetHeight
		// }, 300)
		this.MediaListFn()
		// 热卖套餐
		this.packageFn()
		// 技巧指南
		this.guideFn()
		// let user_info = location.getItem('user_info')
		if (this.$route.query.login == 0 && !user_info) {
			this.$store.state.loginShow = true
		}
	},
	data() {
		return {
			loading: true,
			bannerImg: '',
			mediaList: [
				{
					title: '纸媒体',
					conts: '百家纸媒资源',
				},
				{
					title: '资讯门户',
					conts: '万家资讯门户资源',
				},
				{
					title: '自媒体',
					conts: '十万+自媒体资源',
				},
				{
					title: '媒体套餐',
					conts: '多个优势行业套餐',
				},
			],
			mediaRlList: [],
			mediaRlList2: [],
			mediaRlList3: [],
			hot_setMealList: [], //热卖套餐
			scrollTopbox: 0,
			scrollTopNum: 0,
			// 动态数据
			middleValue2: {
				value1: 10,
				value2: 17000,
				value3: 200,
			},
			// 优势
			advantList: [
				{
					imgUrl1: require('../assets/home/gif/team.png'),
					imgUrl2: require('../assets/home/gif/team-link.gif'),
					title: '专业性团队',
					content: '专业的媒体筛选团队配合专业的文稿撰写团队，双重保障您的曝光。',
				},
				{
					imgUrl1: require('../assets/home/gif/protect.png'),
					imgUrl2: require('../assets/home/gif/protect-link.gif'),
					title: '优质效果保障',
					content: '推荐媒体综合收录率达90%以上，提升品牌曝光度及美誉度。',
				},
				{
					imgUrl1: require('../assets/home/gif/Chat.png'),
					imgUrl2: require('../assets/home/gif/Chat-link.gif'),
					title: '全程专业服务',
					content: '稿件状态实时反馈对接，一对一指导协助，快速响应售后无忧。',
				},

				{
					imgUrl1: require('../assets/home/gif/Video.png'),
					imgUrl2: require('../assets/home/gif/Video-link.gif'),
					title: '媒介资源丰富多样',
					content: '资源涵盖资讯门户、自媒体、纸媒、短视频，形式多样，选择多样。',
				},
				{
					imgUrl1: require('../assets/home/gif/Edit.png'),
					imgUrl2: require('../assets/home/gif/Edit-link.gif'),
					title: '专业代写发',
					content: '深耕软文发布行业，懂媒体，懂市场，资深团队为您提供专业的引导与服务。',
				},
				{
					imgUrl1: require('../assets/home/gif/Wallet.png'),
					imgUrl2: require('../assets/home/gif/Wallet-link.gif'),
					title: '平台技术支持',
					content: '专业技术团队，持续优化平台操作功能，保证平台运行速度和稳定',
				},
			],
			// 海量媒体资源
			MoreMediaList: [
				require('../assets/home/media/1.png'),
				require('../assets/home/media/2.png'),
				require('../assets/home/media/3.png'),
				require('../assets/home/media/4.png'),
				require('../assets/home/media/5.png'),
				require('../assets/home/media/6.png'),
				require('../assets/home/media/7.png'),
				require('../assets/home/media/8.png'),
				require('../assets/home/media/9.png'),
				require('../assets/home/media/10.png'),
				require('../assets/home/media/11.png'),
				require('../assets/home/media/12.png'),
				require('../assets/home/media/13.png'),
				require('../assets/home/media/14.png'),
				require('../assets/home/media/15.png'),
			],
			// 技巧指南显示隐藏
			vadeShow: 0,
			vadeList2: [],
			vadeList3: [],
			vadeList4: [],
		}
	},
	methods: {
		goPack() {
			this.$router.push({
				path: '/media/SetMedia'
			})
		},
		BannerImgFn() {
			this.curlGet('/api/advert/detail', {
				mark: 'banner_01'
			}).then(res => {
				console.log(res);
				if (res.data.code) {
					this.bannerImg = res.data.data[0].image
					this.loading = false
				}
			})
		},
		MediaListFn() {
			this.curlGet('/api/medium/rec').then(res => {
				if (res.data.code) {
					this.mediaRlList = res.data.data[1] ? res.data.data[1].list.slice(0, 4) : []
					this.mediaRlList2 = res.data.data[2] ? res.data.data[2].list.slice(0, 4) : []
					this.mediaRlList3 = res.data.data[3].list ? res.data.data[3].list.slice(0, 4) : []
				}
			})
		},
		scrolling() {
			// 滚动条距文档顶部的距离
			let scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop
			// 滚动条滚动的距离
			// let scrollStep = scrollTop - this.oldScrollTop;
			// 更新——滚动前，滚动条距文档顶部的距离
			// this.oldScrollTop = scrollTop;
			// if (scrollStep > 0) {
			//     this.scrollTopNum = scrollStep
			// }
			// 判断是否到了最顶部
			// if (scrollTop <= 0) {
			//     this.scrollTopNum = 0
			// }
			this.scrollTopNum = scrollTop
		},
		handleMouseEnter(item) {
			if (item == '营销技巧' && this.vadeShow != 0) {
				this.vadeShow = 0
			} else if (item == '拍摄技巧' && this.vadeShow != 1) {
				this.vadeShow = 1
			} else if (item == '写稿技巧' && this.vadeShow != 2) {
				this.vadeShow = 2
			}
		},
		packageFn() {
			this.curlGet('/api/package/rec').then(res => {
				if (res.data.code) {
					console.log('套餐:', res.data);
					this.hot_setMealList = res.data.data
				}
			})
		},
		guideFn() {
			this.curlGet('/api/index/guide').then(res => {
				if (res.data.code) {
					this.vadeList2 = res.data.data[2]
					this.vadeList3 = res.data.data[3]
					this.vadeList4 = res.data.data[4]
				}
			})
		},
		goDetail(item) {
			// let url = 
			this.$router.push({
				path: '/GuideDetail',
				query: {
					id: item.id
				},
			})
			// window.open(url.href, '_blank')
		},
		goMedia(index) {
			console.log(index);
			switch (index) {
				case 0:
					this.$router.push('/media/PaperMedia')
					break;
				case 1:
					this.$router.push('/media/News')
					break;
				case 2:
					this.$router.push('/media/WeMedia')
					break;
				case 3:
					this.$router.push('/media/SetMedia')
					break;

				default:
					break;
			}
		}
	},
}
</script>


<style lang=scss scoped>
@import '../scss/Home';
</style>